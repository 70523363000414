import React, { useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import { QuoteContext } from "../../../Context/QuoteContext"
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo"
import styled from "@emotion/styled"
import {
  verifyMobilePin,
  requestMobilePin,
  modalResetError,
  toggleDispatchedPin,
  formResetError,
  updateField,
} from "../../../Context/Actions"
import { useTheme } from "emotion-theming"
import Icon from "../../../components/Icon/Icon"
import OneTimePin from "../../../components/GetQuote/Fields/OneTimePin/OneTimePin"
import OutlinedButton from "../../../components/GetQuote/Fields/Buttons/OutlinedButton"
import LinkButton from "../../../components/GetQuote/Fields/Buttons/LinkButton"
import Overlay from "../../../components/GlobalAssets/Overlay/overlay"
import {
  FormMessage,
  validateOneTimePin,
} from "../../../components/GetQuote/Fields/fields"
import Modal from "../../../components/GlobalAssets/Modal/modal"
import { QuoteContainer } from "./lagg-till-mobilnummer"
import ButtonLoader from "../../../components/GlobalAssets/ButtonLoader"

const VerifyMobileNumberPage = ({ location }) => {
  const theme = useTheme()
  const { state, dispatch } = useContext(QuoteContext)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [DisplayNewPin, setDisplayNewPin] = useState(false)
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    let timeout = {}

    if (state.isLoading) {
      timeout = setTimeout(() => {
        setShowLoader(true)
      }, 50)
    } else {
      setShowLoader(false)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [state.isLoading])

  useEffect(() => {
    dispatch(formResetError())

    const timeout = setTimeout(() => {
      setDisplayNewPin(true)
    }, 35000)

    return () => {
      clearTimeout(timeout) // this guarantees to run right before the next effect
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!state.hasQuote && !state.hasLoadedQuote) {
    if (typeof window === "undefined") {
      return null
    }

    // If reloaded go back to start
    if (location.search !== "") {
      navigate(`/s?${new URLSearchParams(location.search).toString()}`)
    } else {
      navigate("/")
    }

    return null
  }

  const handleSubmit = e => {
    e.preventDefault()

    const isValid = validateOneTimePin(state.fields.oneTimePin.fieldValue)

    if (isValid.valid) {
      dispatch(verifyMobilePin(1))
    } else {
      dispatch(
        updateField({
          name: "oneTimePin",
          value: state.fields.oneTimePin.fieldValue,
          valid: false,
          message: isValid.message,
          dirty: true,
        })
      )
    }
  }

  const customerStatus = state.quote.result.customerStatus

  const requestNewPin = e => {
    dispatch(requestMobilePin())

    // Set timeout to prevent spam
    setDisplayNewPin(false)
    setTimeout(() => setDisplayNewPin(true), 35000)

    setButtonDisabled(true)
    setTimeout(() => setButtonDisabled(false), 35000)
  }

  return (
    <Layout>
      <SEO title="evoli - Verifiera ditt mobilnummer" noIndex={true} />
      <QuoteContainer>
        <QuoteHeroContent>
          <Icon
            name="code"
            bgColor={theme.colors.CardContainer.iconBackground}
            iconColor={theme.colors.CardContainer.iconColor}
            iconSize="25"
          />

          <h1>
            Ange koden som skickats till
            <span>
              {customerStatus === "active"
                ? state.quote.result.telephoneNumber
                : state.fields.telephoneNumber.fieldValue}
            </span>
          </h1>
          <p>Efter att du angett koden så sparar vi ditt mobilnummer.</p>

          <FormContainer onSubmit={handleSubmit} noValidate>
            <OneTimePin />

            <Button disabled={showLoader}>
              {!showLoader ? "Gå till betalning" : <ButtonLoader />}
            </Button>

            <FormMessage message={state.errors.formError} />

            <SecondaryActions>
              {DisplayNewPin && (
                <OutlinedButton
                  text="Skicka en ny kod"
                  type="button"
                  onClick={() => setConfirm(true)}
                />
              )}
            </SecondaryActions>
          </FormContainer>
        </QuoteHeroContent>
      </QuoteContainer>
      <Overlay show={confirm} />

      <Modal show={confirm}>
        <Icon
          name="code"
          bgColor={theme.colors.CardContainer.iconBackground}
          iconColor={theme.colors.CardContainer.iconColor}
          iconSize="25"
        />
        {state.dispatchedPin ? (
          <ModalLastStep
            phoneNumber={
              customerStatus === "active"
                ? state.quote.result.telephoneNumber
                : state.fields.telephoneNumber.fieldValue
            }
          />
        ) : (
          <ModalDefault
            phoneNumber={
              customerStatus === "active"
                ? state.quote.result.telephoneNumber
                : state.fields.telephoneNumber.fieldValue
            }
            requestNewPin={requestNewPin}
            buttonDisabled={buttonDisabled}
            modalError={state.errors.modalError}
            dispatch={dispatch}
            isLoading={state.isLoading}
          />
        )}

        <LinkButton
          text="Stäng"
          type="button"
          onClick={() => {
            setConfirm(false)
            dispatch(toggleDispatchedPin(false))
            dispatch(modalResetError())
          }}
        />
      </Modal>
    </Layout>
  )
}

export default VerifyMobileNumberPage

const QuoteHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  h1,
  p {
    max-width: 305px;
  }

  h1 {
    margin: 0;
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;
    max-width: 13ch;

    span {
      display: block;
      color: #5558ea;
      margin-top: 5px;
    }
  }

  p {
    margin-top: 4px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;

    max-width: 200px;
  }
`

const SecondaryActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  justify-content: space-between;
  /* height: 39px; */
  button:last-child {
    margin-top: 15px;
  }
  margin-bottom: 40px;

  @media (max-width: 320px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`

const FormContainer = styled.form`
  margin: 0;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ModalDefault = ({
  phoneNumber,
  requestNewPin,
  buttonDisabled,
  modalError,
  isLoading,
}) => {
  return (
    <>
      <h1>
        Skicka en ny engångskod till
        <span>{phoneNumber}</span>
      </h1>

      <Button
        type="button"
        style={{ width: "100%" }}
        onClick={requestNewPin}
        disabled={buttonDisabled}
      >
        {isLoading ? <ButtonLoader /> : "Ja, skicka en ny engångskod"}
      </Button>

      <FormMessage message={modalError} />
    </>
  )
}

const ModalLastStep = ({ phoneNumber }) => {
  return (
    <>
      <div>
        <h1>
          Vi har skickat en engångskod till
          <span>{phoneNumber}</span>
        </h1>
      </div>

      <p style={{ height: "48px", maxWidth: "300px" }}>
        Vänligen klicka på stäng nedan och ange sedan engångskoden vi skickat
        till din mobil.
      </p>
    </>
  )
}

const Button = styled.button`
  width: 206px;
  background: linear-gradient(
    180deg,
    rgba(107, 60, 231, 1) 5%,
    rgba(87, 55, 213, 1) 100%
  );
  color: #ffffff;
  border-radius: 0.3rem;
  font-size: 16px;
  /* font-weight: bold; */
  height: 48px;
  border: 0;
  margin-top: 40px;
  /* padding: 10px 20px; */

  @media (max-width: 320px) {
    margin-top: 20px;
  }

  :disabled {
    opacity: 0.3;

    :hover {
      cursor: not-allowed;
    }
  }

  :hover {
    background: linear-gradient(
      180deg,
      rgba(107, 60, 231, 0.9) 5%,
      rgba(87, 55, 213, 0.9) 100%
    );
    cursor: pointer;
  }
`
